$tabstrip-indicator-size: 4px;
$tabstrip-item-text: $primary;
$tabstrip-item-hovered-text: $primary;
$tabstrip-item-hovered-bg: rgba($primary, 0.08);
$tabstrip-item-selected-text: $base-text;
$tabstrip-item-disabled-text: rgba($base-text, 0.6);
$tabstrip-item-disabled-bg: rgba(black, 0.02);

@import '@progress/kendo-theme-material/scss/panelbar/_index.scss';
@import '@progress/kendo-theme-material/scss/tabstrip/_index.scss';

.k-tabstrip {
	.k-tabstrip-items {
		text-transform: none;

		> .k-item:not(.k-state-active) {
			opacity: 1;
		}

		.k-item {
			font-weight: 600;

			&.k-state-disabled {
				@include fill(
					$kendo-tabstrip-item-disabled-text,
					$kendo-tabstrip-item-disabled-bg,
					$kendo-tabstrip-item-border,
					$kendo-tabstrip-item-gradient
				);
			}
		}
	}
}

.k-tabstrip-items-wrapper .k-item.k-active::after {
	border-color: #1c75bc !important;
}

.k-tabstrip {
	.k-tabstrip-items-wrapper {
		.k-tabstrip-items {
			li {
				.k-item.k-state-active {
					border-bottom: 4px solid;
					border-color: #1c75bc;
				}
			}
		}
	}

	ul.k-tabstrip-items {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}
ul.k-tabstrip-items {
	li.k-item.k-active {
		.k-link {
			color: #1c75bc;
		}
	}

	li.k-item:hover {
		color: #1c75bc;
	}
}

kendo-panelbar-item > .k-link,
.panel-bar-title-items {
	font-size: 24px;
	padding-top: 12px;
	padding-bottom: 12px;
	font-weight: 600;
}

//default panelbar styling is a grey background
kendo-panelbar-item > .k-link,
.panel-bar-title-items {
	color: #514e4b !important;
	background-color: #e3e3e3 !important;
}

//if needing a white panel bar background
kendo-panelbar-item.white-background > .k-link,
.panel-bar-title-items {
	color: #514e4b !important;
	background-color: #ffffff !important;
}

kendo-panelbar-item > span {
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
}

.k-panelbar-toggle {
	width: 16px;
	vertical-align: middle;
	height: 36px !important;
	font-family: Open Sans !important;
	font-weight: 600 !important;
	font-size: 24px !important;
}

.k-panelbar {
	border: none;
}

// todo: follow up
.k-panelbar > .k-item {
	box-shadow: $container-shadow;
	border: 1px solid $primary;

	.kpa-item {
		padding: 30px;

		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}
	}

	.k-header > .k-icon[class*='k-panelbar'] {
		&::before {
			@include font-awesome-light;
		}

		&.k-i-arrow-s::before {
			content: '\f078';
		}

		&.k-i-chevron-down::before {
			content: '\f078';
		}

		&.k-i-arrow-n::before {
			content: '\f077';
		}

		&.k-i-arrow-chevron-up::before {
			content: '\f077';
		}
	}

	.k-group {
		padding: 30px;

		// todo: follow up
		> .k-item {
			border: 1px solid $primary;
			border-radius: $border-radius;

			& + .k-item {
				margin-top: 30px;
			}
		}
	}

	& + .k-item {
		margin-top: 34px;
	}

	.k-link,
	.k-link:hover,
	.k-link.k-state-selected,
	.k-group > .k-item > .k-link,
	.k-group > .k-item > .k-link:hover,
	.k-group > .k-item > .k-link.k-state-selected {
		padding-left: 20px;
		padding-right: 20px;
		background-color: #e3e3e3;
		color: $base-text;
	}

	.k-link,
	.k-link:hover,
	.k-link.k-state-selected {
		font-size: 24px;
		padding-top: 12px;
		padding-bottom: 12px;
		font-weight: 600;
	}

	.k-group > .k-item > .k-link,
	.k-group > .k-item > .k-link:hover,
	.k-group > .k-item > .k-link.k-state-selected {
		font-size: 16px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-weight: 700;
	}
}

.k-panelbar > .k-item,
.k-grid,
.k-card {
	.k-panelbar .k-panelbar > .k-item,
	.k-card {
		box-shadow: none;
	}
}

.k-breadcrumb-container {
	display: flex;
	flex-direction: row;
	gap: 5px;
	ol {
		list-style: none;
	}
	li {
		list-style: none;
	}
	.k-breadcrumb-root-link:not(.k-disabled) {
		cursor: pointer;
		color: #1c75bc;
	}

	.k-breadcrumb-root-link:not(.k-disabled):hover {
		border: 1px solid #1c75bc;
		border-radius: $border-radius;
		color: #ffffff;
		background-color: #1c75bc;
	}
	.k-breadcrumb-item-text {
		margin: 5px;
	}
}

.k-expander {
	.k-expander-header {
		display: flex;
		flex-direction: row;
		gap: 5px;
		cursor: pointer;
		margin-bottom: 5px;
	}
}

.k-stepper {
	width: 100% !important;
	.k-step-label {
		font-size: 12px;
		.k-step-text {
			max-width: 100%;
		}
	}

	.k-step-current .k-step-indicator {
		border-color: #1c75bc;
		background-color: #1c75bc;
	}

	.k-step-current:hover .k-step-indicator {
		border-color: #1c75bc;
		background-color: #1c75bc;
	}

	.k-step-done .k-step-indicator {
		border-color: #1c75bc;
		background-color: #1c75bc;
	}

	.k-step-done:hover .k-step-indicator {
		border-color: #1c75bc;
		background-color: #1c75bc;
	}

	.k-progressbar .k-selected {
		background-color: #1c75bc;
	}
}

.k-step-list-horizontal .k-step-link {
	max-width: 15em;
}

kendo-panelbar.panelbar-margin-small {
	background-color: #fafafa;
	.k-panelbar-item {
		margin-bottom: 20px; /* Adjust the value as needed for spacing */
	}
}

kendo-panelbar.grey-background {
	kendo-panelbar-item {
		.k-panelbar-content {
			background-color: #fafafa;
		}
	}
}
