// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$platform-web-primary: mat.define-palette(mat.$indigo-palette);
$platform-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$platform-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$platform-web-theme: mat.define-light-theme(
	(
		color: (
			primary: $platform-web-primary,
			accent: $platform-web-accent,
			warn: $platform-web-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($platform-web-theme);

@import './kpa-light-theme-blue.scss';

.k-button.k-state-disabled {
	background-color: #cccccc !important;
	color: #878787 !important;
}

.k-button.k-state-disabled.k-outline {
	background-color: #fff !important;
	color: #878787 !important;
	border-color: #878787 !important;
}

.k-button.k-disabled:not(.k-pager-nav) {
	background-color: #cccccc !important;
	color: #878787 !important;
	border-color: #cccccc !important;
}

.k-button.k-disabled.k-outline {
	background-color: #fff !important;
	color: #878787 !important;
	border-color: #878787 !important;
}

.k-dialog-button-layout-stretched {
	padding: 0;
	align-items: flex-end;
}

.mat-button-wrapper {
	line-height: 40px;
	height: 40px;
}

/* Global Card-Legend Styles */

.card-legend {
	border: 1px solid #e9e9e9;
	box-shadow: none;
	padding: 20px;
}

.card-legend-text {
	font-size: 14px;
	color: #514e4b;
	margin-bottom: 0;
}

/* Global Down Arrow Icon Styles */

.k-i-arrow-60-down::before,
.k-i-arrow-n::before,
.k-i-sarrow-n::before,
.k-i-expand-n::before,
.k-i-arrow-s::before,
.k-i-sarrow-s::before,
.k-i-collapse::before,
.k-i-expand-s::before,
.k-i-arrow-chevron-up::before,
.k-i-chevron-down::before {
	content: '\f078';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 600;
	color: #514e4b;
	font-size: small;
}

.k-i-arrow-60-up::before,
.k-i-arrow-n::before,
.k-i-sarrow-n::before,
.k-i-expand-n::before,
.k-i-arrow-chevron-up::before {
	content: '\f077';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 600;
	color: #514e4b;
	font-size: small;
}

/* Global Calendar Icon Styles */

.k-i-calendar {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	color: #514e4b;
}

.k-i-calendar::before {
	content: '\f133';
}

/* Global Input Styles */

.k-textbox-container {
	padding-top: 18px;
	width: 100%;
}

.k-picker-wrap .k-select,
.k-picker-wrap:hover .k-select,
.k-dropdown-wrap .k-select,
.k-dropdown-wrap:hover .k-select {
	opacity: 1;
}

.k-datepicker {
	width: 100%;
}

.k-grid-header-wrap {
	border-right-width: 0;
}

.k-animation-container-shown,
.k-animation-container > .k-popup {
	box-shadow: none;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.k-popup,
.k-list,
.k-reset,
.k-list-scroller,
.k-animation-container-shown {
	margin: 0;
	padding: 0;
}

.k-list .k-item {
	padding: 8px 10px !important;
}

.k-textbox.ng-invalid.ng-touched,
.k-textbox.ng-invalid.ng-dirty {
	border-color: #ed5137;
}

.k-textbox-container .ng-invalid.ng-touched + .k-label,
.k-textbox-container .ng-invalid.ng-dirty + .k-label {
	color: #ed5137;
}

/* Input Floating Labels and Bottom Border Animation */
/* Legacy relic no longer required in latest Kendo control version */

.k-dropdown {
	width: 100%;
}

/* Labels */

.dp-container,
.k-form-field {
	position: relative;
}

.dd-label,
.dp-label {
	display: block;
	text-overflow: ellipsis;
	position: absolute;
	top: 24px;
	left: 15px;
	font-size: 14px;
	color: #514e4b;
	transform: scale(1);
	transform-origin: top left;
	transition-property: top, transform;
	transition-duration: 0.4s;
	transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

.dd-dirty .dd-label,
.dp-dirty .dp-label,
.dp-focus .dp-label {
	transform: scale(0.75);
	top: 3px;
}

.dd-focus .dd-label,
.dp-focus .dp-label {
	color: #1c75bc;
}

.required-field-error .dd-label,
.required-field-error .dp-label {
	color: #ed5137;
}

.dp-container .k-dateinput,
.kendo-dropdown {
	padding-top: 17px;
}

.dp-container .k-dateinput,
.kendo-dropdown.no-top-padding {
	padding-top: 0px;
}

.dd-border,
.dp-border {
	line-height: 2px;
	height: 2px;
	width: 100%;
	display: block;
	margin: -2px auto 0 auto;
	background: white no-repeat;
	background-image: linear-gradient(to bottom, #1c75bc, #1c75bc), linear-gradient(to bottom, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42));
	background-size:
		0 2px,
		100% 1px;
	background-position:
		50% 100%,
		50% 200%;
	transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.dd-focus .dd-border,
.dp-focus .dp-border {
	background-size:
		100% 2px,
		100% 1px;
}

/* Borders */
.dd-border,
.dp-border {
	line-height: 2px;
	height: 2px;
	width: 100%;
	display: block;
	margin: -2px auto 0 auto;
	background: white no-repeat;
	background-image: linear-gradient(to bottom, #1c75bc, #1c75bc), linear-gradient(to bottom, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42));
	background-size:
		0 2px,
		100% 1px;
	background-position:
		50% 100%,
		50% 200%;
	transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.dd-focus .dd-border,
.dp-focus .dp-border {
	background-size:
		100% 2px,
		100% 1px;
}

// Input Uniform Margin Bottom
.k-textbox-container,
.k-form-field,
.dp-container {
	margin-bottom: 30px;
}

.k-textbox-container input,
.k-dropdown-wrap .k-input,
.k-dateinput-wrap .k-input,
.k-picker-wrap .k-input,
.k-textbox,
.k-textarea,
.k-input.k-textbox {
	color: #514e4b;
}

.k-autocomplete:hover,
.k-autocomplete.k-state-hover,
.k-dateinput-wrap:hover,
.k-dateinput-wrap.k-state-hover,
.k-dropdown-wrap:hover,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap:hover,
.k-picker-wrap.k-state-hover,
.k-multiselect-wrap:hover,
.k-multiselect-wrap.k-state-hover,
.k-numeric-wrap:hover,
.k-numeric-wrap.k-state-hover {
	border-color: #514e4b;
}

.k-textbox:hover,
.k-textbox.k-state-hover,
.k-textarea:hover,
.k-textarea.k-state-hover,
.k-input.k-textbox:hover,
.k-input.k-textbox.k-state-hover {
	border-color: #514e4b;
}

.kpa-spinner-wrap .k-dialog-wrapper {
	outline: none !important;
	z-index: 2;
}

.k-dialog-wrapper {
	z-index: 10002 !important;
}

.kpa-spinner-wrap .k-dialog-wrapper .k-overlay {
	width: calc(100% - 280px) !important;
	height: calc(100% - 40px) !important;
	top: 40px !important;
	left: 280px !important;
	background-color: #fff;
	opacity: 0.9 !important;
	z-index: 2;
}

.kpa-spinner-wrap .k-dialog-wrapper .k-window {
	box-shadow: none !important;
	border: 1px solid #e9e9e9;
}

.kpa-employee-search .k-grid-content-locked .k-button {
	overflow-y: hidden;
}

*::-ms-backdrop,
.kpa-employee-search .k-grid-content-locked .k-button .fa-ellipsis-v {
	margin-top: 3px;
}

.kpa-employee-search .k-grid-content-locked .k-button .fa-ellipsis-vertical {
	margin-top: 3px;
}

.k-grid-content {
	overflow-y: auto;
}

.k-dropdown-wrap .k-input {
	padding-top: 12px;
}

.kpa-error-card .fal::before,
.kpa-status-card .fal::before {
	font-size: 23px;
	margin-right: 11px;
	font-weight: 300;
	vertical-align: middle;
}

.k-panelbar > .k-item > .k-link:hover,
.k-panelbar > .k-item > .k-link.k-state-hover,
.k-panelbar > .k-item > .k-link.k-state-focused {
	background-color: #ffffff !important;
}

.k-panelbar .k-group > .k-item > .k-link:hover,
.k-panelbar .k-group > .k-item > .k-link.k-state-hover,
.k-panelbar .k-group > .k-item > .k-link.k-state-focused {
	background-color: #ffffff !important;
}

.k-chip-list:after {
	content: '\f078' !important;
	font-family: 'Font Awesome 5 Pro';
	font-size: 13px;
	font-weight: 300;
	color: #514e4b;
	font-size: small;
	position: absolute;
	float: right;
	width: 16px;
	height: 16px;
	top: 7px;
	right: 12px;
	cursor: pointer;
}

.k-multiselect-wrap .k-clear-value {
	display: none !important;
}

.k-filtercell {
	.k-multiselect {
		.k-chip-list:after {
			content: '\f078' !important;
			font-family: 'Font Awesome 5 Pro';
			font-size: 13px;
			font-weight: 300;
			color: #514e4b;
			font-size: small;
			position: absolute;
			float: right;
			width: 16px;
			height: 16px;
			top: 7px;
			right: 12px;
			cursor: pointer;
		}
	}
}

kendo-upload .k-button:not(.k-icon-button) {
	background-color: transparent !important;
	border: 2px solid #1c75bc !important;
	color: #1c75bc;
}

kendo-upload.no-border .k-button {
	background-color: transparent !important;
	color: #1c75bc;
}

kpa-item-attachment-list kendo-upload-action-buttons {
	display: none !important;
}

kpa-item-attachment-list kendo-upload.read-only .k-dropzone {
	display: none !important;
}

kpa-item-attachment-list kendo-upload-status-total {
	display: none !important;
}

kpa-item-attachment-list .k-upload .k-dropzone {
	width: auto;
}

.mat-snack-bar-container {
	background: none;
	box-shadow: none;
}

.grid {
	.k-grid-content {
		overflow-y: auto;
		overflow-x: hidden;
	}
}
